import { useEffect, useRef, useState } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { Box, Typography } from '@mui/material';

interface Supporters {
  salutation?: string | null;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string | null;
  address?: string;
  city?: string | null;
  state?: string | null;
  zip_code?: string | null;
  id: number;
  engagementScore?: number;
  considerationStage?: string;
  latitude?: number; // Changed from lat
  longitude?: number; // Changed from lng
}

interface SupportersMapProps {
  supporters: Supporters[];
  selectedSupporter: Supporters | null;
  setSelectedSupporter: (supporter: Supporters | null) => void;
  centerMap: { lat: number; lng: number };
  setCenterMap: (center: { lat: number; lng: number }) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
}

const mapContainerStyle = {
  width: '100%',
  height: '500px',
};

const SupportersMap: React.FC<SupportersMapProps> = ({
  supporters,
  selectedSupporter,
  setSelectedSupporter,
  centerMap,
  setCenterMap,
  zoom,
  setZoom,
}) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [infoWindowPos, setInfoWindowPos] = useState<{ lat: number; lng: number } | null>(null);

  // This useEffect triggers when a new supporter is selected
  useEffect(() => {
    if (selectedSupporter) {
      const matchedSupporter = supporters.find(
        (supporter) => supporter.id === selectedSupporter.id
      );

      if (matchedSupporter && matchedSupporter.latitude != null && matchedSupporter.longitude != null) {
        setCenterMap({ lat: matchedSupporter.latitude, lng: matchedSupporter.longitude });
        setZoom(10); // Zoom in to the selected supporter's location
        setInfoWindowPos({ lat: matchedSupporter.latitude, lng: matchedSupporter.longitude }); // Set the position for InfoWindow
      }
    }
  }, [selectedSupporter, supporters, setCenterMap, setZoom]);

  const handleMarkerClick = (supporter: Supporters) => {
    setSelectedSupporter(supporter);
    setInfoWindowPos({ lat: supporter.latitude as number, lng: supporter.longitude as number });
  };

  const handleCloseInfoWindow = () => {
    setSelectedSupporter(null);
    setInfoWindowPos(null);
  };

  const handleBoundsChanged = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      if (bounds) {
        const visibleSupporters = supporters.filter((supporter) => {
          if (supporter.latitude && supporter.longitude) {
            return bounds.contains({ lat: supporter.latitude, lng: supporter.longitude });
          }
          return false;
        });
        // Update logic as needed if filtering is required.
      }
    }
  };

  return (
    <Box sx={{ borderRadius: '5px', overflow: 'hidden' }}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={zoom}
        center={centerMap}
        onLoad={(map) => {
          mapRef.current = map;
        }}
        onBoundsChanged={handleBoundsChanged}
        options={{
          gestureHandling: 'greedy', // Improve responsiveness
        }}
      >
        {supporters.map(
          (supporter) =>
            supporter.latitude &&
            supporter.longitude && (
              <Marker
                key={supporter.email}
                position={{ lat: supporter.latitude, lng: supporter.longitude }}
                onClick={() => handleMarkerClick(supporter)}
              />
            )
        )}

        {infoWindowPos && selectedSupporter && (
          <InfoWindow position={infoWindowPos} onCloseClick={handleCloseInfoWindow}>
            <div>
              <Typography variant="h6">
                {selectedSupporter.salutation} {selectedSupporter.first_name} {selectedSupporter.last_name}
              </Typography>
              <Typography>
                Address: {selectedSupporter.address}, {selectedSupporter.city}, {selectedSupporter.state} {selectedSupporter.zip_code}
              </Typography>
              <Typography>Phone: {selectedSupporter.phone_number}</Typography>
              <Typography>Engagement Score: {selectedSupporter.engagementScore}</Typography>
              <Typography>Consideration Stage: {selectedSupporter.considerationStage}</Typography>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </Box>
  );
};

export default SupportersMap;
